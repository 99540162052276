<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Relawan</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>NIK : </label>
                  <input
                    v-model="pendukung.nik_relawan"
                    type="text"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group text-uppercase">
                  <label>Nama: </label>
                  <input
                    v-model="pendukung.nama_relawan"
                    type="text"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Jenis Kelamin : </label>
                  <input
                    type="text"
                    v-model="pendukung.jenkel_relawan"
                    class="form-control text-uppercase"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Telp/HP : </label>
                  <input
                    v-model="pendukung.hp_relawan"
                    type="number"
                    readonly
                    class="form-control text-uppercase"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label>Alamat: </label>
                  <textarea
                    v-model="pendukung.alamat_relawan"
                    class="form-control text-uppercase"
                    placeholder="Alamat"
                    rows="3"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="target_relawan">Target : </label>
                  <input
                    type="number"
                    v-model="form.target_relawan"
                    class="form-control"
                    name="target_relawan"
                    style="text-transform: uppercase"
                    required
                    :class="{
                      'is-invalid': formValidate.target_relawan,
                    }"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="username">Username : </label>
                  <input
                    type="text"
                    v-model="form.username"
                    class="form-control"
                    name="username"
                    required
                    :class="{
                      'is-invalid': formValidate.username,
                    }"
                  />
                  <div class="text-danger" v-html="formValidate.username"></div>
                </div>
              </div>
              <div class="col-md-12" v-if="formValidate.id_pendukung">
                <div class="alert alert-danger">
                  <h6 v-html="formValidate.id_pendukung"></h6>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      pendukung: {
        nik_relawan: "",
        nama_relawan: "",
        jenkel_relawan: "",
        alamat_relawan: "",
        hp_relawan: "",
      },
      form: {
        tim_relawan: "",
        target_relawan: "",
        username: "",
      },
      id_pendukung: "",
      loading: false,
      showUserAccess: true,
      formValidate: [],
      timList: [],
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.id_pendukung = id;
      this.fetchData();
    }
    this.fetchTim();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/pendukung/detail/" + this.id_pendukung)
        .then((response) => {
          this.pendukung.nik_relawan = response.data.data.nik_pendukung;
          this.pendukung.nama_relawan = response.data.data.nama_pendukung;
          this.pendukung.jenkel_relawan = response.data.data.jenkel_pendukung;
          this.pendukung.alamat_relawan = response.data.data.alamat_pendukung;
          this.pendukung.hp_relawan = response.data.data.hp_pendukung;
          let nama = response.data.data.nama_pendukung;
          nama = nama.split(" ");
          nama = nama[0];
          this.form.username = nama.toLowerCase().replace(/\s/g, ".") + ".rps";
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchTim() {
      axios
        .get("/v1/tim/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.timList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      formData.append("id_pendukung", this.id_pendukung);
      formData.append("full_name", this.pendukung.nama_relawan);
      formData.append("phone_number", this.pendukung.hp_relawan);

      axios
        .post("/v1/relawan/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: "Sukses",
              text: "Simpan Data Sukses",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.push("/data-relawan/");
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
